<template>
  <div>
    <loading v-if="isLoading" />
    <div v-else>
      <table class="table is-bordered is-size-7">
        <tbody v-for="category in orderedData" :key="category.name">
          <tr class="has-background-light">
            <th colspan="2">
              {{ category.fields[0].categoryLabel }}
            </th>
          </tr>
          <tr v-for="field in category.fields" :key="field.field">
            <td>
              {{ field.label }}:
              <code v-if="field.new">{{ field.new }}</code>
              <span v-else class="has-text-grey">--</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapSnapshot } from "@src/services/siteChange";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query
} from "@firebase/firestore";
export default {
  name: "SiteChangeSnapshot",
  props: {
    siteId: {
      required: true,
      type: String
    },
    changeId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      change: {},
      snapshot: {}
    };
  },
  computed: {
    mappedData() {
      return mapSnapshot({
        data: this.snapshot.data,
        timestamp: this.snapshot.timestamp,
        changeId: this.change._id,
        siteId: this.change.siteRef.id
      });
    },
    orderedData() {
      return this.$_(this.mappedData)
        .groupBy("category")
        .map(fields => {
          return {
            name: fields[0].categoryLabel,
            fields: this.$_.sortBy(fields, ["field"], ["asc"])
          };
        })
        .orderBy(["name", "asc"])
        .value();
    }
  },
  async created() {
    await this.getChange();
    await this.getSnapshot();
    this.isLoading = false;
  },
  methods: {
    async getChange() {
      const changeRef = doc(
        this.$firestore,
        `sites/${this.siteId}/changes/${this.changeId}`
      );
      const change = await getDoc(changeRef);
      this.$set(this, "change", change.data());
    },
    async getSnapshot() {
      const snapshotsRef = query(
        collection(
          this.$firestore,
          `sites/${this.siteId}/changes/${this.changeId}/snapshots`
        ),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      const snapshots = await getDocs(snapshotsRef);
      this.snapshot = !snapshots.empty ? snapshots.docs[0].data() : null;
      this.$emit("snapshot", this.snapshot);
    }
  }
};
</script>

<style lang="scss" scoped>
tbody {
  &:not(:first-child)::before {
    content: "";
    display: block;
    height: 1.5rem;
  }
}
</style>
